import React, { Component } from "react";
import styled, { keyframes, css } from "styled-components";
import {
  NavBar,
  Input,
  Button,
  Text,
  Row,
  Column,
  Notification
} from "../../globalComponents";
import Mom from "../../assets/mom_day_2020.png";
import windowSize from "react-window-size";
import Theme from "../../globalComponents/Theme.js";
import emailjs from "emailjs-com";
import { fadeIn } from "react-animations";

class Contact extends Component {
  state = {
    name: "",
    nameError: "",
    email: "",
    emailError: "",
    message: "",
    messageError: "",
    success: false,
    error: ""
  };
  verify = () => {
    const {
      name,
      email,
      message,
      nameError,
      emailError,
      messageError
    } = this.state;
    var error = false;
    if (!name) {
      error = true;
      this.setState({ nameError: "Required" });
    } else this.setState({ nameError: "" });
    if (!email) {
      error = true;
      this.setState({ emailError: "Required" });
    } else this.setState({ emailError: "" });
    if (!message) {
      error = true;
      this.setState({ messageError: "Required" });
    } else this.setState({ messageError: "" });
    return !error;
  };
  submit = () => {
    const { name, email, message } = this.state;
    if (this.verify()) {
      var params = {
        name: name,
        email: email,
        message: message
      };
      emailjs
        .send("gmail", "nitely_contact", params, "user_7rLWPKgFLjDlVkOf1VArh")
        .then(response => {
          if (response) {
            this.setState({ success: true });
          }
        })
        .catch(error => this.setState({ error: error }));
    }
  };

  render() {
    const { nameError, emailError, messageError, success, error } = this.state;
    const mobile = this.props.windowWidth < 750;
    return (
      <div style={{ backgroundColor: "black", display: "flex" }}>
        <img src={Mom} style={{ height: "100vh", margin: "auto" }} />
        <Text
          size="8vh"
          position="absolute"
          color="black"
          margin="7vh auto auto 35vw"
          animation={css`5s ${keyframes`${fadeIn}`}`}
          animationDelay={4}
        >
          Happy Mother's Day!
        </Text>
        <Notification
          title={error ? "Error Sending Email" : "Message Sent"}
          message={
            error
              ? "There was an issue sending your email. Please try again or contact jhewitt@nitely.dev directly"
              : "Your message has been sent and we will get back to you as soon as possible"
          }
          open={success || error}
          onClose={() => this.setState({ success: false, error: "" })}
          error={error}
        />
      </div>
    );
  }
}
export default windowSize(Contact);
