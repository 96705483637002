import React, { Component } from "react";
import {
  NavBar,
  Section,
  Row,
  Column,
  Image,
  Text,
  InfoBox,
  Button,
} from "../globalComponents";
import Theme from "../globalComponents/Theme.js";
import Banner from "../assets/logos/banner.png";
import Snm from "../assets/SNMlogo.png";
import windowSize from "react-window-size";
import styled from "styled-components";

class Packages extends Component {
  state = {
    talk: false,
  };

  passTest = (pass) => {
    this.setState({ talk: pass });
  };

  render() {
    const { talk } = this.state;
    const mobile = this.props.windowWidth < 750;
    return (
      <NavBar history={this.props.history}>
        <Text size={mobile ? "7vw" : "5vw"} margin="2vh auto 1vh auto">
          Packages
        </Text>
        <InfoBox width="50vw" margin="0 auto 0 auto">
          <Text
            color="black"
            textAlign="center"
            size={mobile ? "3vw" : "1.2vw"}
          >
            Need a custom website or application thats not listed below? <br />
            Contact us for a free consultation.
          </Text>
          <Button
            margin="2vh auto auto auto"
            fontSize={mobile ? "3vw" : "1.2vw"}
            onClick={() => this.props.history.push("/contact")}
          >
            Contact Sales
          </Button>
        </InfoBox>
        <Text size={mobile ? "5vw" : "3vw"} margin="2vh auto 0 1vw">
          Standard Website
        </Text>
        <Section height="auto" backgroundColor={Theme.BLUE}>
          <Row width="100vw" margin="2vh 0 2vh 0">
            <InfoBox width={mobile ? "90vw" : "60vw"}>
              <Text
                color="black"
                textAlign="center"
                size={mobile ? "6vw" : "2vw"}
              >
                Need a basic website to show off your work?
              </Text>
              <Text color="black" size={mobile ? "4vw" : "1.5vw"}>
                Give us your logo, theme or color pallet to work with or let us
                create your brand image ourselves all with self managed content
                allowing you to control your website without having to build it
                yourself.
                <p />
                Prices starting as low as:
              </Text>
              <Text color="black" size={mobile ? "8vw" : "5vw"}>
                $300
              </Text>
              <Row mobile={mobile} width="100%" margin="5vh auto auto auto">
                <Column width={mobile ? "100%" : "28vw"} margin="0 auto 0 auto">
                  <Text color="black" size={mobile ? "5vw" : "1.5vw"}>
                    What's Included
                  </Text>
                  <ul style={{ textAlign: "left", marginTop: 0 }}>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        Personalized website with branded theme
                      </Text>
                    </li>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        Single page site showcasing what you offer
                      </Text>
                    </li>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        Feeds to show off your services, work and products
                      </Text>
                    </li>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        Optional Blog/News page for making posts to your site
                      </Text>
                    </li>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        Contact Form: <br />
                        Make it easy for visitor to contact you with a built in
                        contact form
                      </Text>
                    </li>
                  </ul>
                </Column>
                <Column width={mobile ? "100%" : "28vw"} margin="0 auto 0 auto">
                  <Text color="black" size={mobile ? "5vw" : "1.5vw"}>
                    Add Ons
                  </Text>
                  <ul style={{ textAlign: "left", marginTop: "0" }}>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        Custom Domain name (https://yoursite.com): <br />
                        provide your own at no cost or let us aquire and manage
                        one for you
                      </Text>
                    </li>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        Branding/Logo Design:
                        <br />
                        If your having trouble branding yourself let us throw
                        some ideas and logos your way
                      </Text>
                    </li>
                  </ul>
                </Column>
              </Row>
              <Text
                color="black"
                textAlign="center"
                size={mobile ? "6vw" : "2vw"}
              >
                Contact our sales team to get started.
              </Text>
              <Button
                margin="2vh auto auto auto"
                fontSize={mobile ? "3vw" : "1.2vw"}
                onClick={() => this.props.history.push("/contact")}
              >
                Contact Sales
              </Button>
            </InfoBox>
          </Row>
        </Section>
        <Text size={mobile ? "5vw" : "3vw"} margin="4vh auto 0 1vw">
          Website with Recruitment
        </Text>
        <Section height="auto" backgroundColor={Theme.BLUE}>
          <Row width="100vw" margin="2vh 0 2vh 0">
            <InfoBox width={mobile ? "90vw" : "60vw"}>
              <Text
                color="black"
                textAlign="center"
                size={mobile ? "6vw" : "2vw"}
              >
                Company website with careers section for hiring?
              </Text>
              <Text color="black" size={mobile ? "4vw" : "1.5vw"}>
                Our custom website with careers page to post positions and
                opening at your company. All positions and applicants are
                managed via Aviary CMS allowing you to easily build and automate
                your recruitment process.
                <p />
                Prices starting as low as:
              </Text>
              <Text color="black" size={mobile ? "8vw" : "5vw"}>
                $800
              </Text>
              <Row mobile={mobile} width="100%" margin="5vh auto auto auto">
                <Column width={mobile ? "100%" : "28vw"} margin="0 auto 0 auto">
                  <Text color="black" size={mobile ? "5vw" : "1.5vw"}>
                    What's Included
                  </Text>
                  <ul style={{ textAlign: "left", marginTop: 0 }}>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        All features included in Standard Website Package
                        plus...
                      </Text>
                    </li>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        Careers section for visitors to apply to open positions
                      </Text>
                    </li>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        location specific positions
                      </Text>
                    </li>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        fully customizeable hiring processes with builtin form
                        generation
                      </Text>
                    </li>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        automated notification system to alert hiring managers
                        and applicants of status changes
                      </Text>
                    </li>
                  </ul>
                </Column>
                <Column width={mobile ? "100%" : "28vw"} margin="0 auto 0 auto">
                  <Text color="black" size={mobile ? "5vw" : "1.5vw"}>
                    Add Ons
                  </Text>
                  <ul style={{ textAlign: "left", marginTop: "0" }}>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        Custom Domain name (https://yoursite.com): <br />
                        provide your own at no cost or let us aquire and manage
                        one for you
                      </Text>
                    </li>
                    <li>
                      <Text color="black" size={mobile ? "3vw" : "1vw"}>
                        Branding/Logo Design:
                        <br />
                        If your having trouble branding yourself let us throw
                        some ideas and logos your way
                      </Text>
                    </li>
                  </ul>
                </Column>
              </Row>
              <Text
                color="black"
                textAlign="center"
                size={mobile ? "6vw" : "2vw"}
              >
                Request a demo to see what our recruitment solution can offer
                you.
              </Text>
              <Button
                margin="2vh auto auto auto"
                fontSize={mobile ? "3vw" : "1.2vw"}
                onClick={() => this.props.history.push("/contact")}
              >
                Contact Sales
              </Button>
            </InfoBox>
          </Row>
        </Section>
      </NavBar>
    );
  }
}

export default windowSize(Packages);
