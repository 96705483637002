import React, { Component } from "react";
import styled, { keyframes, css } from "styled-components";
import { NavButton } from "./";
import Theme from "./Theme.js";
import { fadeInDown, fadeOutUp, pulse } from "react-animations";

const Menu = styled.ul`
  display: ${props => (props.open ? "block" : "none")};
  flex-direction: column;
  background-color: ${Theme.BLACK};
  box-shadow: 0px 0.8vh 1vh 0.1vh ${Theme.BLUE};
  position: absolute;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
  list-style: none;
  margin: ${props => props.margin || "8vh 0 0 0"};
  padding: 1vh 0 1vh 0;
  border: 0;
  font-size: 100%;
  width: 100%;
  font: inherit;
  vertical-align: baseline;
  z-index: 1;
  animation: ${props => props.animation};
  animation-delay: ${props => props.animationDelay};
  animation-fill-mode: both;
`;
const Option = styled.button`
  outline: none;
  border: none;
  color: white;
  width: 80%;
  margin: 1vh auto auto auto;
  cursor: pointer;
  font-size: ${props => (props.mobile ? "1.5vh" : "2vh")};
  font-family: ${Theme.MAIN_FONT};
  background: none;
  &:hover {
    animation: 2.5s ${keyframes`${pulse}`} infinite;
    animation-fill-mode: forwards;
    transition: color 1s ease;
    color: ${Theme.BLUE};
  }
`;
export default class Dropdown extends Component {
  state = {
    open: "initialize"
  };
  render() {
    const { label, options = [], containerStyle, mobile } = this.props;
    return (
      <div
        style={
          containerStyle || {
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflow: "visible"
          }
        }
        onMouseLeave={() => this.setState({ open: false })}
        onMouseEnter={() => setTimeout(() => this.setState({ open: true }))}
      >
        <NavButton
          animation={this.props.animation}
          disableHover={true}
          onClick={() => this.setState({ open: true })}
          mobile={mobile}
        >
          {label}
        </NavButton>
        <Menu
          open={this.state.open !== "initialize"}
          animation={
            this.state.open
              ? css`.3s ${keyframes`${fadeInDown}`}`
              : css`.15s ${keyframes`${fadeOutUp}`}`
          }
          animationDelay=".05s"
        >
          {options.map((option, index) => (
            <Option
              key={option.label}
              mobile={mobile}
              onClick={() => this.props.history.push(option.route)}
            >
              {option.label}
            </Option>
          ))}
        </Menu>
      </div>
    );
  }
}
