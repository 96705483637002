import React, { Component, Window } from "react";
import styled, { keyframes, css } from "styled-components";
import Circular from "../assets/logos/light.png";
import { Dropdown, NavButton, Text, Row } from "./";
import Theme from "./Theme.js";
import { fadeInDown, rollIn } from "react-animations";
import Mountain from "../assets/mountain_template.jpg";
import windowSize from "react-window-size";
const routes = [
  {
    label: "Home",
    route: "/home",
  },
  {
    label: "Contact",
    route: "/contact",
  },
  {
    label: "Products and Clients",
    route: "menu",
    menu: [
      { label: "Products", route: "/products" },
      { label: "Clients", route: "/partners" },
      { label: "Packages", route: "/packages" },
    ],
  },
];

class NavBar extends Component {
  state = {
    initialize: this.props.history.location.pathname === "/home",
  };
  render() {
    const { initialize } = this.state;
    const mobile = this.props.windowWidth < 600;
    const tiny = this.props.windowWidth < 400;
    if (initialize)
      setTimeout(() => this.setState({ initialize: false }), 1500);
    return (
      <div>
        <div
          style={{
            display: "flex",
            height: "8vh",
            flexDirection: "row",
            width: "100vw",
            backgroundColor: Theme.BLACK,
            boxShadow: `0 0 10px ${Theme.BLUE}`,
            zIndex: 90,
            position: "fixed",
          }}
        >
          <NavButton
            disableHover={true}
            animation={initialize && css`1s ${keyframes`${rollIn}`}`}
            onClick={() => this.props.history.push("/")}
          >
            <Row noWrap>
              <img
                src={Circular}
                style={{
                  marginTop: "-1vh",
                  height: mobile ? "7vh" : "10vh",
                }}
              />
              {console.log("mobile: ", mobile)}
              <Text hidden={mobile} size="4vh" margin="auto 0 2vh auto">
                Nitely
              </Text>
            </Row>
          </NavButton>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "auto",
              marginLeft: "auto",
            }}
          >
            {routes.map((route) => {
              if (route.menu)
                return (
                  <Dropdown
                    history={this.props.history}
                    label={route.label}
                    options={route.menu}
                    disableHover={initialize}
                    mobile={mobile}
                    animation={
                      initialize &&
                      css` 1s
                        ${keyframes`${fadeInDown}`}
                      `
                    }
                  ></Dropdown>
                );
              else
                return (
                  <NavButton
                    key={route.label}
                    disableHover={initialize}
                    animation={
                      initialize &&
                      css` 1s
                      ${keyframes`${fadeInDown}`}
                    `
                    }
                    onClick={() => {
                      this.setState({ open: false });
                      this.props.history.push(route.route);
                    }}
                    mobile={tiny}
                  >
                    {route.label}
                  </NavButton>
                );
            })}
          </div>
        </div>
        <div
          style={{
            overflow: "hidden",
          }}
        >
          <img
            src={Mountain}
            style={{
              position: "fixed",
              height: "100vh",
              minWidth: "100vw",
              zIndex: -1,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(53,53,90,.0)",
            justifyContent: "flex-start",
            minHeight: "92vh",
            paddingTop: "8vh",
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default windowSize(NavBar);
