import React, { Component } from "react";
import styled from "styled-components";
import Theme from "./Theme.js";

const TextInput = styled.input`
  box-shadow: 0px 0px 1vw ${Theme.BLUE};
  border: ${props => (props.error ? "1px solid red" : "1px solid white")};
  color: white;
  font-family: ${Theme.MAIN_FONT};
  font-weight: bold;
  font-size: ${props => props.fontSize || "1.5vw"};
  width: ${props => props.width || "auto"}
  height: ${props => props.height || "auto"}
  background-color: ${Theme.BLACK};
  padding: 1% 1% 2% 1%;
  ::placeholder {
    color: white;
  }
`;

const MultilineInput = styled.textarea`
  box-shadow: 0px 0px 1vw ${Theme.BLUE};
  border: ${props => (props.error ? "1px solid red" : "1px solid white")};
  max-width: ${props => props.width || "auto"};
  width: ${props => props.width || "auto"};
  max-height: ${props => props.height || "auto"};
  height: ${props => props.height || "auto"};
  color: white;
  font-family: ${Theme.MAIN_FONT};
  font-weight: bold;
  font-size: ${props => props.fontSize || "1.5vw"};
  background-color: ${Theme.BLACK};
  resize: none;
  padding: 1% 1% 2% 1%;
  box-sizing: border-box;
  ::placeholder {
    color: white;
  }
  ::-webkit-scrollbar-track {
    background-color: "grey";
  }
  ::-webkit-scrollbar-thrumb {
    border-radius: 12%;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ErrorText = styled.div`
  color: red;
  font-size: 100%;
  margin-left: 1%;
`;

export default class Input extends Component {
  render() {
    const {
      onChange,
      error,
      name,
      placeholder,
      containerStyle,
      multiline
    } = this.props;
    console.log(error);
    return (
      <div
        style={
          containerStyle || {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%"
          }
        }
      >
        {!multiline ? (
          <TextInput
            {...this.props}
            onChange={e => onChange(e.target.value)}
            error={!!error}
            placeholder={placeholder}
          />
        ) : (
          <MultilineInput
            {...this.props}
            onChange={e => onChange(e.target.value)}
            error={!!error}
            multiline
            placeholder={placeholder}
          />
        )}
        {!!error && <ErrorText>{error || "error"}</ErrorText>}
      </div>
    );
  }
}
