import React, { Component } from "react";
import styled from "styled-components";
import {
  NavBar,
  Input,
  Button,
  Text,
  Row,
  Column,
  Notification
} from "../globalComponents";
import Bird from "../assets/logos/light.png";
import windowSize from "react-window-size";
import Theme from "../globalComponents/Theme.js";
import emailjs from "emailjs-com";

class Contact extends Component {
  state = {
    name: "",
    nameError: "",
    email: "",
    emailError: "",
    message: "",
    messageError: "",
    success: false,
    error: ""
  };
  verify = () => {
    const {
      name,
      email,
      message,
      nameError,
      emailError,
      messageError
    } = this.state;
    var error = false;
    if (!name) {
      error = true;
      this.setState({ nameError: "Required" });
    } else this.setState({ nameError: "" });
    if (!email) {
      error = true;
      this.setState({ emailError: "Required" });
    } else this.setState({ emailError: "" });
    if (!message) {
      error = true;
      this.setState({ messageError: "Required" });
    } else this.setState({ messageError: "" });
    return !error;
  };
  submit = () => {
    const { name, email, message } = this.state;
    if (this.verify()) {
      var params = {
        name: name,
        email: email,
        message: message
      };
      emailjs
        .send("gmail", "nitely_contact", params, "user_7rLWPKgFLjDlVkOf1VArh")
        .then(response => {
          if (response) {
            this.setState({ success: true });
          }
        })
        .catch(error => this.setState({ error: error }));
    }
  };

  render() {
    const { nameError, emailError, messageError, success, error } = this.state;
    const mobile = this.props.windowWidth < 750;
    return (
      <NavBar history={this.props.history}>
        <Column width="90vw" margin=" 3vh auto auto auto">
          <Row margin={mobile ? "auto auto 4vh auto" : "auto auto 2vh 0"}>
            <img
              src={Bird}
              style={{
                height: mobile ? "18vw" : "10vw",
                margin: "0 -1vw auto 0"
              }}
            />
            <Text size={mobile ? "5vw" : "3vw"} shadow margin="auto 0 8% auto">
              ntact us
            </Text>
          </Row>
          <Row
            width={mobile ? "90vw" : "55vw"}
            margin="auto auto 4vh auto"
            justify="space-between"
            noWrap
          >
            <Input
              containerStyle={{
                display: "flex",
                flexDirection: "column",
                width: "48%"
              }}
              onChange={value => this.setState({ name: value })}
              error={nameError}
              placeholder="Your Name"
              fontSize={mobile ? "3vw" : "1vw"}
            />
            <Input
              containerStyle={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
                marginLeft: !mobile && "5%"
              }}
              onChange={value => this.setState({ email: value })}
              error={emailError}
              placeholder="Your Email"
              fontSize={mobile ? "3vw" : "1vw"}
            />
          </Row>
          <Input
            onChange={value => this.setState({ message: value })}
            error={messageError}
            multiline
            max-height="35vh"
            width="100%"
            height={mobile ? "40vh" : "35vh"}
            containerStyle={{
              margin: "auto",
              width: mobile ? "90vw" : "55vw"
            }}
            placeholder="Your Message..."
            fontSize={mobile ? "3vw" : "1vw"}
          />
          <Button
            margin="4vh auto auto auto"
            background={Theme.BLUE}
            onClick={this.submit}
            width={mobile ? "40vw" : "20vw"}
            fontSize={mobile ? "3vw" : "1vw"}
          >
            Send Email
          </Button>
        </Column>
        <Notification
          title={error ? "Error Sending Email" : "Message Sent"}
          message={
            error
              ? "There was an issue sending your email. Please try again or contact jhewitt@nitely.dev directly"
              : "Your message has been sent and we will get back to you as soon as possible"
          }
          open={success || error}
          onClose={() => this.setState({ success: false, error: "" })}
          error={error}
        />
      </NavBar>
    );
  }
}
export default windowSize(Contact);
