import React, { Component } from "react";
import styled, { keyframes, css } from "styled-components";
import { Text } from "./";
import Theme from "./Theme.js";
import LeftArrow from "../assets/LeftArrow.svg";
import RightArrow from "../assets/RightArrow.svg";
import {
  fadeInLeft,
  fadeOutLeft,
  fadeOutRight,
  fadeInRight,
  pulse
} from "react-animations";

const Menu = styled.ul`
  display: ${props => (props.open ? "flex" : "none")};
  flex-direction: column;
  background-color: ${Theme.BLACK};
  box-shadow: 0px 0.8vh 1vh 0.1vh ${Theme.BLUE};
  margin: 0;
  list-style: none;
  padding: 1vh 0 1vh 0;
  border: 2px solid white;
  font-size: 100%;
  font: inherit;
  z-index: 1;
  animation: ${props => props.animation};
  animation-delay: ${props => props.animationDelay};
  animation-fill-mode: both;
`;
const Option = styled.button`
  outline: none;
  border: none;
  color: white;
  width: 80%;
  margin: 1vh auto auto auto;
  cursor: pointer;
  font-size: ${props => (props.mobile ? "1.5vh" : "2vh")};
  font-family: ${Theme.MAIN_FONT};
  background: none;
  &:hover {
    animation: 2.5s ${keyframes`${pulse}`} infinite;
    animation-fill-mode: forwards;
    transition: color 1s ease;
    color: ${Theme.BLUE};
  }
`;

const PopOut = styled.button`
  outline: none;
  border: none;
  display: flex;
  right: ${props => (props.right ? "0" : null)};
  background-color: ${Theme.BLACK};
  color: white;
  padding: ${props =>
    props.right ? "1.5vh 1.5vh 1.5vh 1vh" : "1.5vh 1vh 1.5vh 1.5vh"};
  border-radius: ${props => (props.right ? "10px 0 0 10px" : "0 10px 10px 0")};
  cursor: pointer;
  &:hover {
    animation: 2.5s;
    animation-fill-mode: forwards;
    transition: color 1s ease;
    color: ${Theme.BLUE};
  }
`;
export default class Dropdown extends Component {
  state = {
    open: "initialize"
  };
  render() {
    const {
      label,
      options = [],
      containerStyle,
      mobile,
      right,
      width,
      fontSize,
      top
    } = this.props;
    return (
      <div
        style={
          containerStyle || {
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            overflow: "visible",
            top: top || "10vh",
            right: right ? "0" : null,
            width: width
          }
        }
        onMouseLeave={() => this.setState({ open: false })}
      >
        <PopOut
          right={right}
          width={width}
          top={top}
          animation={this.props.animation}
          disableHover={true}
          onClick={() => this.setState({ open: true })}
          mobile={mobile}
          onMouseEnter={() =>
            setTimeout(() => this.setState({ open: true }), 100)
          }
        >
          {right && (
            <img
              src={LeftArrow}
              style={{
                width: fontSize || "4vh",
                height: "auto",
                margin: "auto 5% auto 1%"
              }}
            />
          )}{" "}
          <Text
            size={fontSize || "4vh"}
            color="inherit"
            margin={!right ? "auto 0 auto auto" : "auto auto auto 0"}
          >
            {label}
          </Text>{" "}
          {!right && (
            <img
              src={RightArrow}
              style={{
                width: fontSize || "4vh",
                height: "auto",
                margin: "auto 1% auto 5%"
              }}
            />
          )}
        </PopOut>
        <Menu
          open={this.state.open !== "initialize"}
          animation={
            this.state.open
              ? css`.5s ${keyframes`${right ? fadeInRight : fadeInLeft}`}`
              : css`.5s ${keyframes`${right ? fadeOutRight : fadeOutLeft}`}`
          }
          animationDelay=".05s"
        >
          {options.map((option, index) => (
            <Option key={option.label} mobile={mobile} onClick={option.onClick}>
              {option.label}
            </Option>
          ))}
        </Menu>
      </div>
    );
  }
}
