import React, { Component } from "react";
import {
  NavBar,
  Section,
  Row,
  Column,
  Image,
  Text,
  InfoBox,
  Button,
} from "../globalComponents";
import Theme from "../globalComponents/Theme.js";
import Banner from "../assets/logos/banner.png";
import Snm from "../assets/SNMlogo.png";
import Ing from "../assets/ING_logo.png";
import Dews from "../assets/Dews_Logo.png";
import windowSize from "react-window-size";
import styled from "styled-components";

class Clients extends Component {
  state = {
    talk: false,
  };

  passTest = (pass) => {
    this.setState({ talk: pass });
  };

  render() {
    const { talk } = this.state;
    const mobile = this.props.windowWidth < 750;
    return (
      <NavBar history={this.props.history}>
        <Text size={mobile ? "7vw" : "5vw"} margin="1vh auto 0 auto">
          Clients
        </Text>{" "}
        <Text size={mobile ? "5vw" : "3vw"} margin="5vh auto 0 2vw">
          Installation Network Group
        </Text>
        <Section backgroundColor={Theme.BLACK} height="auto">
          <Row width="100vw" margin="2vw 0 2vw 0">
            <Image
              src={Ing}
              width={mobile ? "40vw" : "25vw"}
              margin={mobile ? "auto auto 2vh auto" : "auto auto auto 2vw"}
            />
            <InfoBox width="60vw">
              <Text color="black" size={mobile ? "3.5vw" : "1.3vw"}>
                ING is our first client utilizing the Aviary CMS system along
                with our recruiting platform to higher technicians across the
                country. <br />
                ING uses our website with in house recruitment to hire their
                technicians across the easter United States. It has helped
                simplify and automate their hiring process allowing them to
                focus on expanding their operations to more products and
                markets.
                <br />
                "We recruit, train, and dispatch technicians across the country
                to install DirectTV, OrbyTV, Fiber Internet, Sattelite Internet
                and much more. We are the leading installation group partnered
                with DirectTV and growing into new markets every day."
              </Text>
              <Button
                margin="2vh auto auto auto"
                width="70%"
                fontSize={mobile ? "3vw" : "1.2vw"}
                onClick={() => window.open("https://ingroupusa.com")}
              >
                ING Website
              </Button>
            </InfoBox>
          </Row>
        </Section>
        <Text size={mobile ? "5vw" : "3vw"} margin="10vh auto 0 2vw">
          Dew's Crews
        </Text>
        <Section backgroundColor={"#A4EF83"} height="auto">
          <Row width="100vw" margin="2vw 0 2vw 0">
            <Image
              src={Dews}
              width={mobile ? "40vw" : "25vw"}
              margin={mobile ? "auto auto 2vh auto" : "auto auto auto 2vw"}
            />
            <InfoBox width="60vw">
              <Text color="black" size={mobile ? "3.5vw" : "1.3vw"}>
                Dew's Crews installs gutters and gutter guards in multiple
                cities across the eastern United States. Dew's Crews uses our
                standard website with a feed of all their offered products. We
                also did all the branding for Dew's Crews including the logo,
                color scheme and name.
              </Text>
              <Button
                width="70%"
                margin="2vh auto auto auto"
                fontSize={mobile ? "3vw" : "1.2vw"}
                onClick={() => window.open("https://staffnmore.com")}
              >
                Dew's Crews Website
              </Button>
            </InfoBox>
          </Row>
        </Section>
        <Text size={mobile ? "5vw" : "3vw"} margin="10vh auto 0 2vw">
          Staff N More
        </Text>
        <Section backgroundColor={Theme.BLUE} height="auto">
          <Row width="100vw" margin="2vw 0 2vw 0">
            <Image
              src={Snm}
              width={mobile ? "40vw" : "25vw"}
              margin={mobile ? "auto auto 2vh auto" : "auto auto auto 2vw"}
            />
            <InfoBox width="60vw">
              <Text color="black" size={mobile ? "3.5vw" : "1.3vw"}>
                Staff N More is the first client using the new Timecard
                Solutions web application to help manage their outsourced staff
                and bill thier hotels for the hours they work each week. <br />
                "We recruit, train, and place motivated housekeeping staff,
                providing a flexible and cost-effective solution that fits
                within budgets and meets personnel challenges. We provide a
                customized solution, whether you need to outsource the entire
                housekeeping department or just need a few associates."
              </Text>
              <Button
                width="70%"
                margin="2vh auto auto auto"
                fontSize={mobile ? "3vw" : "1.2vw"}
                onClick={() => window.open("https://staffnmore.com")}
              >
                SNM Website
              </Button>
            </InfoBox>
          </Row>
        </Section>
      </NavBar>
    );
  }
}
export default windowSize(Clients);
