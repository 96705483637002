import React from "react";
import logo from "./logo.svg";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Team from "./pages/team";
import Packages from "./pages/packages";
import Products from "./pages/products";
import Clients from "./pages/partners";
import Promotion from "./pages/promotions";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/team" component={Team} />
        <Route path="/packages" component={Packages} />
        <Route path="/products" component={Products} />
        <Route path="/partners" component={Clients} />
        <Route path="/promotions" component={Promotion} />
        <Redirect to="/home" />
      </Switch>
    </Router>
  );
}

export default App;
