import React, { Component } from "react";
import {
  NavBar,
  Section,
  Row,
  Column,
  Image,
  Text,
  InfoBox,
  Button,
} from "../globalComponents";
import Theme from "../globalComponents/Theme.js";
import Banner from "../assets/logos/banner.png";
import Tcs from "../assets/tcs_logo.png";
import Aviary from "../assets/Aviary_Banner.PNG";
import styled from "styled-components";
import windowSize from "react-window-size";

class Products extends Component {
  state = {
    talk: false,
  };

  passTest = (pass) => {
    this.setState({ talk: pass });
  };

  render() {
    const { talk } = this.state;
    const mobile = this.props.windowWidth < 750;
    return (
      <NavBar history={this.props.history}>
        <Text size={mobile ? "7vw" : "5vw"} margin="5vh auto 0 auto">
          Products and Projects
        </Text>
        <Text size={mobile ? "6vw" : "4vw"} margin="10vh auto 0 2vw">
          Timecard Solutions
        </Text>
        <Section backgroundColor={Theme.BLUE} height="auto">
          <Row width="100vw" margin="2vh 0 2vh 0">
            <Image
              src={Tcs}
              borderRadius="1vw"
              width={mobile ? "40vw" : "25vw"}
              margin={mobile ? "auto auto 2vh auto" : "auto auto auto 2vw"}
            />
            <Column>
              <InfoBox width="60vw" margin="auto auto 5vh auto">
                <Text color="black" size={mobile ? "3vw" : "1.3vw"}>
                  Timecard Solutions is a web application developed in
                  partnership with Hotel Solutions. It is designed to help with
                  the daily management of employees and handle the billing to
                  clients for their employees' time. The web app is accompanied
                  by a simple mobile application that acts as an employee portal
                  allowing them to clock in and out as well as view their
                  previous time entries. Management uses the web application to
                  manage employees and handle the billing of clients. Clients
                  have their own login to access and view the employees at their
                  hotel, manage their time entries and view the weekly generated
                  invoices.
                </Text>
              </InfoBox>
              <InfoBox width="60vw">
                <Text color="black" size={mobile ? "4vw" : "1.5vw"}>
                  If you would like to know more or are interested in using
                  Timecard Solutions with your business either contact us or
                  head over to their website.
                </Text>
                <Row margin="4vh auto auto auto">
                  <Button
                    fontSize={mobile ? "3vw" : "1.2vw"}
                    onClick={() => window.open("https://timecardsolutions.com")}
                  >
                    TCS Website
                  </Button>
                  <Button
                    fontSize={mobile ? "3vw" : "1.2vw"}
                    onClick={() => this.props.history.push("/contact")}
                    margin="auto auto auto 1vw"
                  >
                    Contact
                  </Button>
                </Row>
              </InfoBox>
            </Column>
          </Row>
        </Section>
        <Text size={mobile ? "6vw" : "4vw"} margin="5vh auto 0 2vw">
          Aviary CMS
        </Text>
        <Section
          backgroundColor={Theme.BLACK}
          style={{ backgroundOpacity: ".5" }}
          height="auto"
        >
          <Row width="100vw" margin="2vh 0 2vh 0">
            <Image
              src={Aviary}
              borderRadius="1vw"
              width={mobile ? "40vw" : "25vw"}
              margin={mobile ? "auto auto 2vh auto" : "auto auto auto 2vw"}
            />
            <Column>
              <InfoBox width="60vw" margin="auto auto 5vh auto">
                <Text color="black" size={mobile ? "3vw" : "1.3vw"}>
                  Aviary is our CMS solution that powers all the features of our
                  custom made websites. With aviary you can manage content
                  across all of your websites on one easy to use platform.
                  Aviary also features recruitment management allowing you to
                  fully manage and customize your in house hiring process.
                  Aviary Recruitment is a fully customizable hiring solution
                  allowing you to define your own hiring process for each
                  position, generate forms for each step of the process, notify
                  applicants and hiring managers when statuses have changed, and
                  automate steps of your hiring process. Aviary does the work
                  for you so you can focus on running your business.
                </Text>
              </InfoBox>
              <InfoBox width="60vw">
                <Text color="black" size={mobile ? "4vw" : "1.5vw"}>
                  If you would like to request a demo or are ready to try Aviary
                  contact our sales team below.
                </Text>
                <Row margin="4vh auto auto auto">
                  <Button
                    fontSize={mobile ? "3vw" : "1.2vw"}
                    onClick={() => this.props.history.push("/contact")}
                    margin="auto auto auto 1vw"
                  >
                    Contact
                  </Button>
                </Row>
              </InfoBox>
            </Column>
          </Row>
        </Section>
      </NavBar>
    );
  }
}

export default windowSize(Products);
