import React, { Component } from "react";
import {
  NavBar,
  Button,
  Text,
  Row,
  Column,
  Section,
  Hint,
  DemoFrame,
  InfoBox,
  InfoCircle,
  Image,
} from "../globalComponents";
import Theme from "../globalComponents/Theme.js";
import Title from "../assets/birdTitle.png";
import Arrows from "../assets/DownArrow.svg";
import Design from "../assets/design.svg";
import Code from "../assets/code.svg";
import Deploy from "../assets/deploy.svg";
import styled, { keyframes, css } from "styled-components";
import WebApp from "../assets/TCS-WebApp.png";
import MobileWeb from "../assets/TCS-WebMobile.png";
import MobileApp from "../assets/TCS-Mobile.png";
import WebSite from "../assets/INGSite_web.PNG";
import MobileSite from "../assets/INGSite_mobile.PNG";
import windowSize from "react-window-size";
import moment from "moment";
import {
  bounceOutUp,
  bounceInLeft,
  bounceInUp,
  lightSpeedIn,
  bounce,
  fadeIn,
} from "react-animations";

class Home extends Component {
  state = {
    section: 1,
    lastScroll: null,
  };

  render() {
    const { section } = this.state;
    const mobile = this.props.windowWidth < 800;
    const wrap = this.props.windowWidth < 1900;
    return (
      <NavBar history={this.props.history}>
        <Section height="96vh" transition={"in"}>
          <Image
            width={mobile ? "100vw" : "70vw"}
            margin={mobile ? "5vh auto 0 auto " : "5vh auto 0 auto "}
            animation={section === 1 && css`2s ${keyframes`${fadeIn}`}`}
            src={Title}
          />
          <Text
            animation={section === 1 && css`2s ${keyframes`${fadeIn}`}`}
            shadow
            margin="4vh auto 5vh auto"
            size={mobile ? "5vw" : "2vw"}
            align="top"
            animationDelay="1s"
          >
            Applications | Websites | Graphics
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "auto auto 4vh auto",
            }}
          >
            <Button
              shadow={"0 0 1vw black"}
              animation={css`2s ${keyframes`${fadeIn}`}`}
              animationDelay="1s"
              width={mobile ? "40vw" : "20vw"}
              fontSize={mobile ? "4vw" : "2vw"}
              onClick={() => this.props.history.push("/packages")}
            >
              Pricing / Packages
            </Button>
            <Button
              shadow={"0 0 1vw black"}
              animation={css`2s ${keyframes`${fadeIn}`}`}
              animationDelay="1s"
              width={mobile ? "40vw" : "20vw"}
              fontSize={mobile ? "4vw" : "2vw"}
              margin="auto auto auto 2vw"
              onClick={() => this.props.history.push("/contact")}
            >
              Contact Sales
            </Button>
          </div>
          <Text
            animation={section === 1 && css`2s ${keyframes`${fadeIn}`}`}
            shadow
            margin="4vh auto 5vh auto"
            size={mobile ? "5vw" : "2vw"}
            align="top"
            animationDelay="1s"
          >
            Learn More Below
          </Text>
        </Section>
        <Section
          ref={(ref) => (this.second = ref)}
          height="auto"
          margin="0"
          padding="0 0 2vh 0"
          backgroundColor={Theme.BLUE}
        >
          <Text
            shadow
            size={mobile ? "4vh" : "6vh"}
            margin="5vh auto auto auto"
          >
            We Handle It All
          </Text>
          <div
            style={{
              border: "2px solid black",
              width: mobile ? "65vw" : "40vw",
              margin: "auto",
              boxShadow: "0 1px 1vw black",
            }}
          />
          <Text
            shadow
            size={mobile ? "3vh" : "4vh"}
            margin={mobile ? "5vh 2vw auto 2vw" : "5vh 5vw auto 5vw"}
          >
            Let us build you an application from the ground up or help you fill
            in the missing piece with a flexible plan to meet any budget.
          </Text>
          <Row justify="center" width={"100%"} margin="20vh 0 2vh 0">
            <Column>
              <Text shadow size={mobile ? "3vh" : "4vh"}>
                Design
              </Text>
              <InfoCircle width={mobile ? "20vw" : "15vw"}>
                <img
                  src={Design}
                  style={{ margin: "auto", width: "100%", height: "auto" }}
                />
              </InfoCircle>
            </Column>
            <Column>
              <Text shadow size={mobile ? "3vh" : "4vh"}>
                Code
              </Text>
              <InfoCircle width={mobile ? "20vw" : "15vw"}>
                <img
                  src={Code}
                  style={{
                    width: "100%",
                    margin: "auto",
                    height: "auto",
                  }}
                />
              </InfoCircle>
            </Column>
            <Column>
              <Text shadow size={mobile ? "3vh" : "4vh"}>
                Deploy
              </Text>
              <InfoCircle width={mobile ? "20vw" : "15vw"}>
                <img
                  src={Deploy}
                  style={{
                    width: "100%",
                    margin: "auto",
                    height: "auto",
                  }}
                />
              </InfoCircle>
            </Column>
          </Row>
        </Section>
        <Section
          ref={(ref) => (this.second = ref)}
          margin="0"
          padding="0 0 2vh 0"
        >
          <Row width={"95vw"} noWrap={!wrap}>
            <DemoFrame mobile={wrap}>
              <Text
                size="4vh"
                margin="-4vh auto auto auto"
                position="absolute"
                shadow
              >
                Web Applications
              </Text>
              <img
                src={mobile ? MobileWeb : WebApp}
                style={{ display: "flex", height: "60vh", width: "auto" }}
              />
            </DemoFrame>
            <InfoBox
              shadow
              width={wrap ? "80vw" : "auto"}
              margin={wrap ? "2vh auto auto auto " : "auto auto auto 2vw"}
              height="auto"
            >
              <Text color="black">
                Our Web Applications provide interactive user experiences that
                digitize your current business and allow automation of everyday
                tasks.
                <p /> This application is a demo of Timecard Solutions, an
                application designed to handle user clockin/clockout as well as
                automate the accounting process with their clients.
                <p />
                Try it out on the demo site yourself!
              </Text>
            </InfoBox>
          </Row>
        </Section>
        <Section
          ref={(ref) => (this.second = ref)}
          height={"auto"}
          margin="0"
          padding="0 0 2vh 0"
          backgroundColor={Theme.BLUE}
        >
          <Row width={"95vw"} noWrap={!wrap}>
            <InfoBox
              shadow
              width={wrap ? "80vw" : "20vw"}
              margin={wrap ? "2vh auto auto auto " : "auto"}
            >
              <Text color="black">
                Our Mobile Applications, whether they are standalone apps or
                mobile portals for one of our web applications, are built with
                efficiency and simplicity.
                <p /> We value user experience over all else creating easy to
                use interfaces that are accessible to all.
              </Text>
            </InfoBox>
            <DemoFrame mobile={wrap}>
              <Text
                size="4vh"
                margin="-4vh auto auto auto"
                position="absolute"
                shadow
              >
                Mobile Apps
              </Text>
              <img
                src={MobileApp}
                style={{ display: "flex", height: "100%", width: "auto" }}
              />
            </DemoFrame>
          </Row>
        </Section>

        <Section
          ref={(ref) => (this.second = ref)}
          height="auto"
          margin="0"
          padding="0 0 2vh 0"
        >
          <Row width={"95vw"} noWrap={!wrap}>
            <DemoFrame mobile={wrap}>
              <Text
                size="4vh"
                margin="-4vh auto auto auto"
                position="absolute"
                shadow
              >
                Web Sites
              </Text>
              <img
                src={mobile ? MobileSite : WebSite}
                style={{ display: "flex", height: "60vh", width: "auto" }}
              />
            </DemoFrame>
            <InfoBox
              shadow
              width={wrap ? "80vw" : "auto"}
              margin={wrap ? "2vh auto auto auto " : "auto auto auto 2vw"}
            >
              <Text color="black">
                Need something a little more flashy that showcases what your
                business is all about?
                <p /> We offer flexible plans to meet your budget needs and
                won't charge you for the services you dont need
                <p />
                Give us your designs and logos or let us help create your brand
                image for you.
              </Text>
            </InfoBox>
          </Row>
        </Section>
        <Section
          ref={(ref) => (this.second = ref)}
          margin="0"
          padding="0 0 2vh 0"
          backgroundColor={Theme.BLUE}
        >
          <Text
            shadow
            size={mobile ? "4vh" : "6vh"}
            margin="5vh auto 10vh auto"
          >
            Provided Services
          </Text>
          <Row justify="space-between" width="100%">
            <InfoBox
              width={mobile ? "60vw" : "25vw"}
              height={mobile ? "auto" : "25vh"}
              margin={mobile ? "2vh auto auto auto" : "auto"}
            >
              <Text
                color="black"
                size={mobile ? "6vw" : "2vw"}
                margin="0 auto 1vh auto"
              >
                Full-stack Development
              </Text>
              <Text color="black" size={mobile ? "4vw" : "1.5vw"} align="left">
                {" "}
                Our full-stack development team can build you any application
                you need no matter the size. We'll build a quality application
                with a focus on efficiency and user experience to help make your
                everyday business as easy as possible.
              </Text>
            </InfoBox>
            <InfoBox
              width={mobile ? "60vw" : "25vw"}
              height={mobile ? "auto" : "25vh"}
              margin={mobile ? "2vh auto auto auto" : "auto"}
            >
              <Text
                color="black"
                size={mobile ? "6vw" : "2vw"}
                margin="0 auto 2vh auto"
              >
                Graphic Design
              </Text>
              <Text color="black" size={mobile ? "4vw" : "1.5vw"}>
                {" "}
                Need company logos and graphics? <p />
                We can help you brand your company from scratch, remodel
                existing logos, create event fliers, advertisements, whatever
                you need!
              </Text>
            </InfoBox>
            <InfoBox
              width={mobile ? "60vw" : "25vw"}
              height={mobile ? "auto" : "25vh"}
              margin={mobile ? "2vh auto auto auto" : "auto"}
            >
              <Text
                color="black"
                size={mobile ? "6vw" : "2vw"}
                margin="0 auto 1vh auto"
              >
                Application Remodeling
              </Text>
              <Text color="black" size={mobile ? "4vw" : "1.5vw"}>
                {" "}
                Outdated website costing you business?
                <p />
                We can help you modernize your website and update the user
                experience to give you the boost you need to stay ahead of the
                competition.
              </Text>
            </InfoBox>
          </Row>
        </Section>
        <Section
          ref={(ref) => (this.second = ref)}
          height="20vh"
          margin="0"
          backgroundColor={Theme.BLACK}
        >
          <Text size="150%" margin="auto">
            (629) 209-8341
            <p />
            jhewitt@nitely.dev
            <p />
            &copy;{` ${moment().format("YYYY")} Nitely Development LLC`}
          </Text>
        </Section>
      </NavBar>
    );
  }
}

export default windowSize(Home);
