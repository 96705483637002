const theme = {
  PAGE_BACKGROUND: "#4C4C4C",
  MAIN_COLOR: "#e00606",
  BUTTON_COLOR: "rgba(255, 174, 53)",
  BORDER_COLOR: "#727272",
  NAV_COLOR: "#161616",
  DARK_GREY: "#535354",
  LIGHT_GREY: "#79797A",
  BLUE: "rgba(88,99,135, .80)", //"#586387",
  BLACK: "rgba(24,24,25,.95)",
  WHITE: "rgba(255,255,255,.5)",
  ORANGE: "rgba(255, 174, 53)",
  BANNER_FONT: "mr-eaves-sans",
  MAIN_FONT: "mr-eaves-sans",
};

export default theme;
