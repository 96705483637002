import React, { Component } from "react";
import styled from "styled-components";
import {
  NavBar,
  Button,
  Text,
  Column,
  Row,
  NavButton,
  InfoBox,
  Image,
  Drawer
} from "../globalComponents";
import Theme from "../globalComponents/Theme.js";
import John from "../assets/john.png";
import Default from "../assets/default.png";
import windowSize from "react-window-size";

const PictureFrame = styled.div`
  height: 10vw;
  width: 10vw;
  flex-shrink: 0;
  border-radius: 50%;
  margin: -10vh 2vw auto 1vw;
  border: 2px solid black;
  float: left;
`;

const Border = styled.div`
align: center
  display: inline;
  height: ${props => (props.mobile ? "43vh" : "43vh")};
  width: ${props => (props.mobile ? "43vw" : "40vw")};
  border: 2px none black;
  background-color: #282828;
  border-radius: 12px;
  opacity: 0.95;
  box-shadow: 0px 3px 10px 10px rgb(64, 64, 64);
  margin: 2vh 0 5vh 15vw;
`;

const team = [
  {
    name: "John Hewitt",
    title: "Owner - Lead Developer",
    picture: John,
    description:
      "John Hewitt is the owner and lead developer of Nitely Development LLC." +
      " He works tirelessly to ensure that all of his clients are satisfied with " +
      "the products they've been provided. John spends most of his free time working on new technologies for Nitely to offer to better meet the needs of our growing list of clients. " +
      `On top of handling most of the development here at Nitely, John also handles customer support and product maintenance to ensure our monthly 99.99 % uptime is always met and that he is always available to help a customer in need.`
  },
  {
    name: "Ty Romero",
    title: "Apprentice Developer",
    description:
      "Ty is Nitely's first apprentice, and the latest addition to the Nitely development team. Ty lives and works in Nashville and will be Nitely's representative in the area after completion of his apprenticeship." +
      "Ty is hard at work training to be a full stack developer. He is currently working on Nitely's latest technology Nitely CMS (coming soon) as well as learning about how our current applications operate."
  },
  {
    name: "Madalene Smith",
    title: "HR - Graphic Designer",
    description:
      "Madalene Smith is our faceless designer hiding out in the dark rececess of our workspace. Not much is known about her except she can make some killer graphics and designs for anything we throw at her. "
  }
];

class Team extends Component {
  state = {
    current: 0
  };
  render() {
    const { current } = this.state;
    const mobile = this.props.windowWidth < 750;
    return (
      <NavBar history={this.props.history}>
        <Row
          width={mobile ? "100vw" : "80vw"}
          noWrap={!mobile}
          margin=" 5vh auto auto auto"
        >
          <Image
            src={team[current].picture || Default}
            alt="faceless being"
            width={mobile ? "25vw" : "15vw"}
            borderRadius="100px"
            border={`.2vw solid ${Theme.BLACK}`}
          />
          <Column
            margin={mobile ? "0 auto auto auto" : "auto auto auto 2vw"}
            width="auto"
          >
            <Text
              size={mobile ? "6vw" : "4vw"}
              weight="bold"
              width="100%"
              color="white"
              margin="10vh 29vw 2vh auto"
            >
              {team[current].name}
            </Text>
            <Text
              size={mobile ? "4vw" : "3vw"}
              color="white"
              margin=" auto 18vw auto auto"
            >
              {team[current].title}
            </Text>
            {!mobile && (
              <InfoBox padding="1vw">
                <Text color="black" size="2vh" margin="0 auto auto auto">
                  {team[current].description}
                </Text>
              </InfoBox>
            )}
          </Column>{" "}
          {mobile && (
            <InfoBox width="90vw" padding="1vw" margin="2vh auto auto auto">
              <Text color="black" size="2vh" margin="0 auto auto 0">
                {team[current].description}
              </Text>
            </InfoBox>
          )}
        </Row>
        <Drawer
          right
          label="Team"
          width={mobile ? "30vw" : "15vw"}
          options={[
            {
              label: <Text>John Hewitt</Text>,
              onClick: () => this.setState({ current: 0 })
            },
            {
              label: <Text>Ty Romero</Text>,
              onClick: () => this.setState({ current: 1 })
            }
          ]}
        />
      </NavBar>
    );
  }
}
export default windowSize(Team);
